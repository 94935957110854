define("boekdrukken/models/users/user/products/parameters/book/content", ["exports", "boekdrukken/models/users/user/products/parameters/book/-parameters"], function (_exports, _parameters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _parameters.default.extend({
    paper: (0, _parameters.parameter)('content/paper'),
    changed: (0, _parameters.changed)('paper'),
    didUpdateCoverType: function didUpdateCoverType() {
      this.paper.didUpdateCoverType();
    }
  });

  _exports.default = _default;
});