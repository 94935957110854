define("boekdrukken/models/templates/template/book-hardcover-cover", ["exports", "boekdrukken/models/templates/template/-book-cover"], function (_exports, _bookCover) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assign = Object.assign;

  var _default = _bookCover.default.extend({
    rebuildPreview: function rebuildPreview(opts) {
      return this._super(assign({
        tweak: 5
      }, opts));
    }
  });

  _exports.default = _default;
});