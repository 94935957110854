define("boekdrukken/models/users/user/products/parameters/book/-paper", ["exports", "boekdrukken/models/users/user/products/parameters/book/-parameter"], function (_exports, _parameter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _parameter.default.extend({
    lockable: true,
    all: Ember.computed.readOnly('parameters.component.settings.papers'),
    activePapers: Ember.computed.filterBy('all', 'state', 'active'),
    coverType: Ember.computed.readOnly('parameters.parameters.cover.coverType.value'),
    papers: Ember.computed('activePapers.@each.types', 'coverType', function () {
      var coverType = this.coverType,
          activePapers = this.activePapers;
      return activePapers.filter(function (_ref) {
        var types = _ref.types;
        return types.includes(coverType);
      });
    }).readOnly(),
    selected: Ember.computed('all.@each.id', 'value', function () {
      var all = this.all,
          value = this.value;

      if (!all) {
        return;
      }

      return all.findBy('id', value);
    }).readOnly(),
    value: (0, _parameter.doc)('paper'),
    changed: (0, _parameter.changed)('paper'),
    update: function update(paper) {
      this.parameters.update({
        paper: paper.id
      });
    },
    didUpdateCoverType: function didUpdateCoverType() {
      this.update(this.papers[0]);
    }
  });

  _exports.default = _default;
});