define("boekdrukken/components/ui-application/header/back/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZxNLky2e",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[11,\"href\",[22,\"url\"]],[10,\"class\",\"link\"],[8],[1,[28,\"t\",[\"application.header.back.label\"],null],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-application/header/back/template.hbs"
    }
  });

  _exports.default = _default;
});