define("boekdrukken/models/templates/book-hardcover-cover", ["exports", "boekdrukken/models/templates/-templates-book-cover"], function (_exports, _templatesBookCover) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _templatesBookCover.default.extend({
    type: 'book-hardcover-cover',
    title: 'model.templates.book-hardcover-cover.title',
    dimensions: (0, _templatesBookCover.dimensions)({
      page: {
        width: 176,
        height: 250
      },
      spine: 20,
      tweak: 5,
      crop: 10,
      scale: 10
    }),
    buildNodes: function buildNodes(_ref) {
      var collection = _ref.collection,
          _ref$dimensions = _ref.dimensions,
          scale = _ref$dimensions.scale,
          page = _ref$dimensions.page,
          spine = _ref$dimensions.spine,
          crop = _ref$dimensions.crop,
          tweak = _ref$dimensions.tweak;

      var doc = function doc(props) {
        return collection.doc().new(props);
      };

      var scaled = function scaled(value) {
        return value * scale;
      };

      var width = crop + page.width + spine + page.width + crop;
      var height = crop + page.height + crop;
      return [doc({
        type: 'area',
        identifier: 'background',
        parent: null,
        x: 0,
        y: 0,
        width: scaled(width),
        height: scaled(height),
        position: 1,
        fill: '#fff',
        cropMarks: 'book',
        bleeding: scaled(crop),
        spine: scaled(spine),
        selectable: false,
        visible: true
      }), doc({
        type: 'area',
        identifier: 'back',
        parent: null,
        x: 0,
        y: 0,
        width: scaled(crop + page.width - tweak),
        height: scaled(crop + page.height + crop),
        position: 2,
        selectable: true,
        visible: true
      }), doc({
        type: 'area',
        identifier: 'front',
        parent: null,
        x: scaled(crop + page.width + spine + tweak),
        y: 0,
        width: scaled(page.width + crop - tweak),
        height: scaled(crop + page.height + crop),
        position: 3,
        selectable: true,
        visible: true
      }), doc({
        type: 'area',
        identifier: 'spine',
        parent: null,
        x: scaled(crop + page.width),
        y: 0,
        width: scaled(spine),
        height: scaled(crop + page.height + crop),
        position: 4,
        selectable: true,
        visible: true
      })];
    }
  });

  _exports.default = _default;
});