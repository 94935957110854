define("boekdrukken/models/templates/template/book-paperback-cover", ["exports", "boekdrukken/models/templates/template/-book-cover"], function (_exports, _bookCover) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bookCover.default.extend({});

  _exports.default = _default;
});