define("boekdrukken/models/sketch/base/stage/node/text", ["exports", "boekdrukken/models/sketch/base/stage/node"], function (_exports, _node) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _node.default.extend({
    name: 'model.sketch.base.stage.node.text.name',
    description: Ember.computed.readOnly('name'),
    node: (0, _node.node)({
      type: 'sized'
    }),
    isEditable: true,
    guidelines: (0, _node.guidelines)(),
    x: (0, _node.x)(),
    y: (0, _node.y)(),
    width: (0, _node.width)(),
    height: (0, _node.height)(),
    rotation: (0, _node.rotation)(),
    aspect: (0, _node.aspect)(),
    fill: (0, _node.fill)(),
    color: (0, _node.color)(),
    opacity: (0, _node.opacity)(),
    text: (0, _node.attr)('text', {
      type: 'string'
    }),
    fontFamily: (0, _node.attr)('fontFamily', {
      type: 'string',
      initial: 'Ubuntu Mono'
    }),
    fontWeight: (0, _node.attr)('fontWeight', {
      type: 'number',
      initial: 400
    }),
    fontSize: (0, _node.attr)('fontSize', {
      type: 'number',
      initial: 10,
      min: 0,
      max: 5000
    }),
    lineHeight: (0, _node.attr)('lineHeight', {
      type: 'number',
      initial: 0,
      min: 0,
      max: 10000,
      decimals: 1
    }),
    fontStyle: (0, _node.attr)('fontStyle', {
      type: 'string',
      initial: 'normal'
    }),
    padding: (0, _node.attr)('padding', {
      type: 'number',
      initial: 0,
      min: 0
    }),
    align: (0, _node.attr)('align', {
      type: 'string',
      initial: 'left'
    }),
    verticalAlign: (0, _node.attr)('verticalAlign', {
      type: 'string',
      initial: 'top'
    }),
    _mergeTouched: function _mergeTouched(props) {
      if (props) {
        if (Object.keys(props).includes('text')) {
          props.touched = true;
        }
      }

      return props;
    },
    update: function update(props) {
      props = this._mergeTouched(props);

      this._super(props);
    }
  });

  _exports.default = _default;
});