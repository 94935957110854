define("boekdrukken/util/book-cover", ["exports", "mathjs"], function (_exports, _mathjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.coverConstants = _exports.calculateDimensions = void 0;

  var round = function round(value) {
    return _mathjs.default.round(value, 2);
  };

  var _coverConstants = {
    hardcover: {
      crop: 5,
      tweak: 8
    },
    paperback: {
      crop: 5,
      tweak: 0
    }
  };

  var coverConstants = function coverConstants(coverType) {
    (false && !(!!coverType) && Ember.assert('coverType is required', !!coverType));
    var hash = _coverConstants[coverType];
    (false && !(!!hash) && Ember.assert("cover constants for '".concat(coverType, "' not defined"), !!hash));
    return hash;
  }; // dimensions are in mm
  // paper: users/user/products/settings/book/paper/content
  // thickness: users/user/products/settings/book/thickness


  _exports.coverConstants = coverConstants;

  var calculateDimensions = function calculateDimensions(_ref) {
    var coverType = _ref.coverType,
        _ref$dimensions = _ref.dimensions,
        width = _ref$dimensions.width,
        height = _ref$dimensions.height,
        pageCount = _ref.pageCount,
        thickness = _ref.paper.thickness,
        base = _ref.thickness.base;
    var roundedPageCount = pageCount % 2 === 1 ? pageCount + 1 : pageCount;

    var _coverConstants2 = coverConstants(coverType),
        crop = _coverConstants2.crop,
        tweak = _coverConstants2.tweak;

    var spine = round(_mathjs.default.evaluate('(thickness * roundedPageCount) + base', {
      thickness: thickness,
      roundedPageCount: roundedPageCount,
      base: base
    })); // spine + 10% for hardcovers

    if (coverType === 'hardcover') {
      spine = round(_mathjs.default.evaluate('spine + (spine / 10)', {
        spine: spine
      }));
      width = width - 3 + tweak;
      height += 6;
      width += 10;
      height += 20;
    }

    return {
      page: {
        width: width,
        height: height
      },
      spine: spine,
      crop: crop,
      tweak: tweak,
      total: {
        width: crop + width + spine + width + crop,
        height: crop + height + crop
      }
    };
  };

  _exports.calculateDimensions = calculateDimensions;
});