define("boekdrukken/util/product-data-layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dataLayerProductObject = void 0;

  /*
    {
      id: 'userId/productId',
      type: 'paperback',
      pageSize: { width, height },
      pageCount: 100,
      price: 14.29
    }
  */
  var dataLayerProductObject = function dataLayerProductObject(_ref) {
    var id = _ref.id,
        type = _ref.type,
        pageSize = _ref.pageSize,
        pageCount = _ref.pageCount,
        price = _ref.price;
    var pageSizeString = "".concat(pageSize.width, "x").concat(pageSize.height, "mm");
    var name = "".concat(type, " - ").concat(pageSizeString, " - ").concat(pageCount);
    return {
      name: name,
      id: id,
      price: price,
      quantity: 1
    };
  };

  _exports.dataLayerProductObject = dataLayerProductObject;
});