define("boekdrukken/components/ui-application/header/experiment/start-new-book/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "14nAEe6P",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"link\",\"index\"]],{\"statements\":[[1,[28,\"t\",[\"application.header.start-new-book.label\"],null],false]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "boekdrukken/components/ui-application/header/experiment/start-new-book/template.hbs"
    }
  });

  _exports.default = _default;
});