define("boekdrukken/models/templates/-templates-book-cover", ["exports", "boekdrukken/models/templates/-templates"], function (_exports, _templates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "dimensions", {
    enumerable: true,
    get: function get() {
      return _templates.dimensions;
    }
  });

  var _default = _templates.default.extend({});

  _exports.default = _default;
});