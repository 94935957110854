define("boekdrukken/components/ui-block/pdf/grid/pair/page/component", ["exports", "boekdrukken/components/ui-block/pdf/-util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':page', 'faded:faded', 'detailsHidden:no-details', 'showCropmarks:has-cropmarks', 'page.colorType.id'],
    page: null,
    size: null,
    // { width, height }
    filter: null,
    thumbnail300: Ember.computed.readOnly('page.storage.300x300.serialized'),
    thumbnail800: Ember.computed.readOnly('page.storage.800x800.serialized'),
    thumbnail: Ember.computed('size', 'thumbnail300', 'thumbnail800', function () {
      var size = this.size,
          base = this.thumbnail300,
          large = this.thumbnail800;

      if (!base) {
        return;
      }

      if (size) {
        if (large) {
          if (size.width > base.width || size.height > base.height) {
            return large;
          }
        }
      }

      return base;
    }).readOnly(),
    contentStyle: (0, _util.sizeStyle)('size'),
    showCropmarks: Ember.computed('size', 'cropmarksInset', function () {
      var _this$size = this.size,
          width = _this$size.width,
          height = _this$size.height,
          cropmarksInset = this.cropmarksInset;
      var min = 150;

      if (width > min && height > min) {
        if (cropmarksInset) {
          var inset = function inset(key) {
            return cropmarksInset[key] > 0;
          };

          if (inset('top') || inset('bottom') || inset('left') || inset('right')) {
            return true;
          }
        }
      }
    }).readOnly(),
    cropmarksInset: Ember.computed('size', 'page.boxes.serialized', function () {
      var page = this.page,
          size = this.size;

      if (!page) {
        return;
      }

      var box = function box(key) {
        return page.get("boxes.serialized.".concat(key, ".pt"));
      };

      var art = box('art');
      var trim = box('trim');

      var scale = function scale(dimension, value) {
        return size[dimension] / (art[dimension] / value);
      };

      return {
        top: scale('height', trim.y - art.y),
        left: scale('width', trim.x - art.x),
        bottom: scale('height', art.height - trim.height - trim.y),
        right: scale('width', art.width - trim.width - trim.x)
      };
    }).readOnly(),
    faded: Ember.computed('filter', 'page.colorType.id', function () {
      var filter = this.filter;

      if (!filter) {
        return false;
      }

      var id = this.get('page.colorType.id');
      return id !== filter;
    }).readOnly(),
    detailsHidden: Ember.computed('size', function () {
      var width = this.size.width;
      return width < 100;
    }).readOnly(),
    actions: {
      toggleColorType: function toggleColorType() {
        var page = this.page;
        page && page.toggleColorType();
      },
      select: function select() {
        this.select();
      },
      selectColorType: function selectColorType(type) {
        this.page.updateColorType(type);
      }
    }
  });

  _exports.default = _default;
});