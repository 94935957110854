define("boekdrukken/models/users/user/products/parameters/book/cover/cover-type", ["exports", "boekdrukken/models/users/user/products/parameters/book/-parameter"], function (_exports, _parameter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var t = function t(key) {
    return "block.product.book.step.parameters.cover.cover-type.type.".concat(key);
  };

  var options = Ember.A([{
    value: 'paperback',
    name: t('paperback')
  }, {
    value: 'hardcover',
    name: t('hardcover')
  }]);

  var _default = _parameter.default.extend({
    lockable: true,
    options: options,
    // options: computed('selected', function() {
    //   let { selected } = this;
    //   if(selected && selected.value === 'hardcover') {
    //     return options;
    //   }
    //   return [
    //     options[0]
    //   ];
    // }).readOnly(),
    value: (0, _parameter.doc)('coverType'),
    changed: (0, _parameter.changed)('coverType'),
    selected: Ember.computed('value', function () {
      var value = this.value;
      return options.find(function (option) {
        return option.value === value;
      });
    }).readOnly(),
    didUpdate: function didUpdate() {
      this.parameters.parameters.didUpdateCoverType();
    },
    update: function update(_ref) {
      var coverType = _ref.value;
      this.parameters.update({
        coverType: coverType
      });
      this.didUpdate();
    },
    setDefaultIfNeeded: function setDefaultIfNeeded() {
      if (!this.value) {
        this.update({
          value: 'paperback'
        });
      }
    }
  });

  _exports.default = _default;
});