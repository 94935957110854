define("boekdrukken/components/ui-application/header/experiment/logo/component", ["exports", "boekdrukken/util/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':ui-application-header-logo'],
    url: (0, _computed.config)(function (config) {
      return config.external.home;
    })
  });

  _exports.default = _default;
});